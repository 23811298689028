
import { Alert, Button, Card, Form, Input, message, Row } from 'antd';
import PinInput from 'react-hook-pin-input';
import { FormattedMessage, useIntl } from 'react-intl';
import useApiRequest, { RequestType } from '../../../hooks/useApiRequest';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { authActions } from '../../../store/slices/authSlice';
import { BaseSyntheticEvent, useState } from 'react';
import classes from './Login.module.less';

const Login = () => {
    const { isLoading, error, doRequest } = useApiRequest();
    const [showPinField, setShowPinField] = useState(false);
    const dispatch = useAppDispatch();
    const [form] = Form.useForm();
    const intl = useIntl();

    const onFinishHandler = (values: any) => {
        if (!showPinField) {
            sendCodeHandler();

            return;
        }

        doRequest('/auth/login/', RequestType.Post, values).then((response) => {
            dispatch({
                type: authActions.login,
                payload: response.data
            });
        });
    };

    const sendCodeHandler = () => {
        form.validateFields(['email']).then(() => {
            const email = form.getFieldValue('email');

            doRequest('/auth/ask_admin_code/', RequestType.Post, { email: email }).then(() => {
                message.success(intl.formatMessage({ id: 'code-in-mail-message' }));
            }).finally(() => {
                setShowPinField(true);
            });
        });
    };

    const onPinChangeHandler = (event: BaseSyntheticEvent) => {
        const parent = event.target.parentNode;
        let code = '';

        for (let i = 0; i < parent.children.length; i++) {
            code += parent.children[i].value;
        }

        return code;
    };

    return (
        <Form form={form} layout="vertical" onFinish={onFinishHandler}>
            <Card title={<FormattedMessage id="add-login-code" />} >
                {error && <Alert type="error" message={error} />}
                <Form.Item id="email" name="email" label={<FormattedMessage id="email" />} rules={[{ required: true, type: 'email' }]}>
                    <Input  />
                </Form.Item>

                {showPinField && (
                    <Form.Item id="password" name="password" label={<FormattedMessage id="code" />} getValueFromEvent={onPinChangeHandler} rules={[{ required: true }]}>
                        <div className={classes.PinFields}>
                            <PinInput type='tel' fieldHeight={30} className={classes.PinFields} fields={8} autoFocus />
                        </div>
                    </Form.Item>
                )}

                <Form.Item style={{ marginBottom: '0' }}>
                    <Row justify="space-between">
                        <Button type="primary" htmlType="submit" loading={isLoading} >
                            <FormattedMessage id={showPinField ? 'login' : 'ask-login-code'} />
                        </Button>
                        {!showPinField && <Button onClick={() => setShowPinField(true)} >{<FormattedMessage id="i-already-have-code" />}</Button>}
                        {showPinField && <Button loading={isLoading} onClick={sendCodeHandler} >{<FormattedMessage id="ask-new-code" />}</Button>}
                    </Row>
                </Form.Item>
            </Card>
        </Form>
    );
};

export default Login;