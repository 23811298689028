import { Layout } from 'antd';
import BizplayViews from '../../views/bizplay-views';
import styles from './BizplayLayout.module.less';

const BizplayNews = () => {
    return (
        <Layout style={{ minHeight: '100vh', maxHeight: '100vh', overflow: 'hidden' }} className={styles.Main}>
            <Layout.Content>
                <BizplayViews></BizplayViews>
            </Layout.Content>
        </Layout>
    );
};

export default BizplayNews;