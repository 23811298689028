import { Col, Row } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { EntityCount } from '../../../api-interfaces/Statistics';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import useApiRequest, { RequestType } from '../../../hooks/useApiRequest';
import CountBlock from './CountBlock/CountBlock';
import IdeasStatistics from './IdeasStatistics/IdeasStatistics';
import NewsStatistics from './NewsStatistics/NewsStatistics';
import UserStatistics from './UserStatistics/UserStatistics';

const Index = () => {
    const { doRequest } = useApiRequest();
    const [data, setData] = useState<null|EntityCount>(null);

    useEffect(() => {
        doRequest('/stats/entity_count-data/', RequestType.Get).then(response => {
            setData(response.data);
        });
    }, [doRequest]);

    if (!data) {
        return <h1>Loading</h1>;
    }

    return (
        <>
            <Row gutter={[15, 15]}>
                <CountBlock
                    totalValue={data.user_count[0]}
                    lastWeekValue={data.user_count[data.user_count.length - 1]}
                    title={<FormattedMessage id="users" />}
                    tooltip={<FormattedMessage id="overview.stats.show-active-users" />}
                    link={`${APP_PREFIX_PATH}/users/overview`} />

                <CountBlock
                    totalValue={data.idea_count[0]}
                    lastWeekValue={data.idea_count[data.idea_count.length - 1]}
                    title={<FormattedMessage id="ideas" />}
                    tooltip={<FormattedMessage id="overview.stats.show-active-ideas" />}
                    link={`${APP_PREFIX_PATH}/ideas/overview`} />

                <CountBlock
                    totalValue={data.news_count[0]}
                    lastWeekValue={data.news_count[data.news_count.length - 1]}
                    title={<FormattedMessage id="news-posts" />}
                    tooltip={<FormattedMessage id="overview.stats.show-news-items" />}
                    link={`${APP_PREFIX_PATH}/news/overview`} />

                <CountBlock
                    totalValue={data.comments_count[0]}
                    lastWeekValue={data.comments_count[data.comments_count.length - 1]}
                    title={<FormattedMessage id="comments" />} />

                <Col span={24} xxl={12}>
                    <UserStatistics />
                </Col>

                <Col span={24} xxl={12}>
                    <IdeasStatistics />
                </Col>

                <Col span={24}>
                    <NewsStatistics />
                </Col>
            </Row>
        </>
    );
};

export default Index;