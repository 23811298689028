import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import Overview from '../../../../components/shared-components/Overview';
import { APP_PREFIX_PATH } from '../../../../constants/AppConstants';
import { Link } from 'react-router-dom';
import { IdeasCategorySingle } from '../../../../api-interfaces/Ideas';

const columns = [
    {
        title: <FormattedMessage id="title" />,
        dataIndex: 'name',
        key: 'name',
        render: (title: string, record: IdeasCategorySingle) => (
            <Tooltip title="Edit">
                <Link to={`${APP_PREFIX_PATH}/ideas/categories/${record.pk}`}>{record.name}</Link>
            </Tooltip>
        )
    },
    {
        title: <FormattedMessage id="ideas" />,
        dataIndex: 'idea_set',
        key: 'posts',
        render: (set: number[]) => set.length
    }
];

const IdeasCategories = () => {
    return (
        <Overview
            title={<FormattedMessage id="ideas.categories.overview" />}
            endpoint="/ideas/categories/"
            columns={columns}
            searchKeys={['name']}
            canCreate
            canDelete />
    );
};

export default IdeasCategories;