import React, { Suspense } from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import PageHeader from '../../components/layout-components/PageHeader/PageHeader';
import Departments from './departments';
import DepartmentsForm from './departments/CreateOrUpdate';
import Ideas from './ideas';
import IdeasCategories from './ideas/Categories';
import IdeasCategoriesForm from './ideas/Categories/CreateOrUpdate';
import IdeasView from './ideas/View';
import News from './news';
import NewsCategories from './news/Categories';
import NewsCategoriesForm from './news/Categories/CreateOrUpdate';
import NewsForm from './news/CreateOrUpdate';
/**
 * Lazy loading is useful when the application is getting to big.
 * This will make sure the application downloads faster and has less initial load time.
 */
// const Overview = React.lazy(() => import('./Overview'));
// const Users = React.lazy(() => import('./Users'));
// const UsersCreate = React.lazy(() => import('./Users/Create'));
// const UsersBulkAdd = React.lazy(() => import('./Users/BulkAdd'));
// const Departments = React.lazy(() => import('./Departments'));
// const DepartmentsCreate = React.lazy(() => import('./Departments/Create'));
// const News = React.lazy(() => import('./News'));
// const NewsCreate = React.lazy(() => import('./News/Create'));
// const NewsCategories = React.lazy(() => import('./News/Categories/Categories'));
// const Ideas = React.lazy(() => import('./Ideas'));
// const IdeasCategories = React.lazy(() => import('./Ideas/Categories/Categories'));
/**
 * Since the application is not to big I've chosen to preload
 * all pages for a better user experience.
 */
import Overview from './overview';
import Users from './users';
import UsersBulkAdd from './users/BulkAdd';
import UsersCreate from './users/CreateOrUpdate';

const AppViews = () => {
    return (
        <Suspense fallback={<PageHeader title="Loading..." />}>
            <Routes>
                <Route path="overview" element={<Overview />}/>
                <Route path="users/overview" element={<Users />}/>
                <Route path="users/:id" element={<UsersCreate />}/>
                <Route path="users/create" element={<UsersCreate />}/>
                <Route path="users/bulk/add" element={<UsersBulkAdd />}/>
                <Route path="departments/overview" element={<Departments />}/>
                <Route path="departments/:id" element={<DepartmentsForm />}/>
                <Route path="departments/create" element={<DepartmentsForm />}/>
                <Route path="news/overview" element={<News />}/>
                <Route path="news/create" element={<NewsForm />}/>
                <Route path="news/:id" element={<NewsForm />}/>
                <Route path="news/archived" element={<News />}/>
                <Route path="news/categories" element={<NewsCategories />}/>
                <Route path="news/categories/create" element={<NewsCategoriesForm />}/>
                <Route path="news/categories/:id" element={<NewsCategoriesForm />}/>
                <Route path="ideas/overview" element={<Ideas />}/>
                <Route path="ideas/archived" element={<Ideas />}/>
                <Route path="ideas/categories" element={<IdeasCategories />}/>
                <Route path="ideas/categories/create" element={<IdeasCategoriesForm />}/>
                <Route path="ideas/categories/:id" element={<IdeasCategoriesForm />}/>
                <Route path="ideas/:id" element={<IdeasView />}/>
                <Route path="*" element={<Navigate to='overview' replace />} />
            </Routes>
        </Suspense>
    );
};

export default React.memo(AppViews);