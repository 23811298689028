import { Alert, Button, Card, Col, Form, Row, Select, Upload } from 'antd';
import PageHeader from '../../../components/layout-components/PageHeader/PageHeader';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { DepartmentSingle } from '../../../api-interfaces/Departments';
import useApiRequest, { RequestType } from '../../../hooks/useApiRequest';
import Loader from '../../../components/layout-components/Loader/Loader';
import { UploadOutlined } from '@ant-design/icons';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';

const BulkAddUsers = () => {
    const navigate = useNavigate();
    const [departments, setDepartments] = useState<DepartmentSingle[]|null>(null);
    const { doRequest, error, isLoading } = useApiRequest();
    const [file, setFile] = useState<UploadFile>();

    // Load departments for dropdown
    useEffect(() => {
        doRequest('/departments', RequestType.Get).then(response => {
            setDepartments(response.data);
        });
    }, [doRequest]);

    if (!departments) {
        return (
            <>
                <PageHeader title={<FormattedMessage id="users.bulk-add"  />} />
                <Loader />
            </>
        );
    }

    const onFinishHandler = (values: any) => {
        if (isLoading) return;

        const formData = new FormData();
        formData.append('user_data_file', file as RcFile);

        doRequest(`/users/bulk-add-users/${values.department}/`, RequestType.Post, formData).then(response => {
            navigate(`${APP_PREFIX_PATH}/users/overview`);
        });
    };

    const tooltip = (
        <>

            <FormattedMessage id='paste here the following information:'></FormattedMessage>

            <ul>
                <li><FormattedMessage id='email, Firstname, Lastname'></FormattedMessage></li>
                <li><FormattedMessage id='values must be comma separated'></FormattedMessage></li>
                <li><FormattedMessage id='one user per line'></FormattedMessage></li>
            </ul>
            <FormattedMessage id='existing users will remain untouched.'></FormattedMessage>
        </>
    );

    const beforeUploadHandler = (file: UploadFile): boolean => {
        setFile(file);

        return false;
    };

    const onRemoveHandler = () => {
        setFile(undefined);
    };

    return (
        <>
            <PageHeader title={<FormattedMessage id="users.bulk-add"  />} />
            <Form layout="vertical" onFinish={onFinishHandler}>
                <Card>
                    {error && <Alert type="error" style={{ marginBottom: '15px' }} message={<FormattedMessage id="error" />} description={<FormattedMessage id="users.bulk.error" />} showIcon />}
                    <Alert type="warning" style={{ marginBottom: '15px' }} message={<FormattedMessage id="warning" />} description={<FormattedMessage id="users.bulk.warning" />} showIcon />
                    <Row gutter={[15, 0]}>
                        <Col span={24}>
                            <Form.Item name="user_data_file" label={<FormattedMessage id="users.bulk.data" />} rules={[{ required: true }]} tooltip={tooltip}>
                                <Upload accept=".csv" beforeUpload={beforeUploadHandler} onRemove={onRemoveHandler} fileList={file ? [file] : []}>
                                    <Button icon={<UploadOutlined />}>Select File</Button>
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="department" label={<FormattedMessage id="department" />} rules={[{ required: true }]}>
                                <Select
                                    placeholder={<FormattedMessage id="make-a-choice" />}
                                >
                                    {departments.map(department => <Select.Option key={department.pk} value={department.pk}>{department.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    <FormattedMessage id="submit" />
                                </Button>

                                <a style={{ marginLeft: '10px' }} target="_blank" href="/static/example.csv">
                                    <FormattedMessage id="users.bulk.example" />
                                </a>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </>
    );
};

export default BulkAddUsers;