import { CKEditor as CKEditorPlugin } from '@ckeditor/ckeditor5-react';
// @ts-ignore
import MarkDownEditor from '../../../plugins/CKEditor/build/ckeditor';

import { Form } from 'antd';
import { Rule } from 'rc-field-form/lib/interface';
import { ReactNode } from 'react';

interface CKEditorProps {
    name: string,
    label: string|ReactNode,
    rules?: Rule[]
}

const editorConfiguration = {
    // plugins: [
    //     Markdown
    // ],
    // toolbar: [ 'bold', 'italic', 'link', '|', 'bulletedList', 'numberedList', '|', 'undo', 'redo' ],
    height: '200px'
};

const CKEditor = (props: CKEditorProps) => {
    const getValueFromEvent = (event: Event, editor: any) => {
        return editor.getData();
    };

    return (
        <Form.Item name={props.name} valuePropName="data" getValueFromEvent={getValueFromEvent} label={props.label} rules={props.rules}>
            <CKEditorPlugin config={editorConfiguration} editor={MarkDownEditor} onReady={editor => {
                editor.editing.view.change((writer: any) => {
                    writer.setStyle('height', editorConfiguration.height, editor.editing.view.document.getRoot());
                });
            }} />
        </Form.Item>
    );
};

export default CKEditor;