import { Card, Col, Comment, Descriptions, Empty, Image, Row, Tooltip } from 'antd';
import DescriptionsItem from 'antd/lib/descriptions/Item';
import moment from 'moment';
import { ReactNode, useEffect, useState } from 'react';
import { FormattedDate, FormattedMessage } from 'react-intl';
import { useNavigate, useParams } from 'react-router-dom';
import { IdeaImageSingle, IdeaSingle } from '../../../api-interfaces/Ideas';
import Loader from '../../../components/layout-components/Loader/Loader';
import PageHeader from '../../../components/layout-components/PageHeader/PageHeader';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import useApiRequest, { RequestType } from '../../../hooks/useApiRequest';

const IdeasView = () => {
    const navigate = useNavigate();
    const { doRequest, isLoading } = useApiRequest();
    const { id } = useParams();
    const [currentData, setCurrentData] = useState<IdeaSingle|null>(null);

    // Retrieve data if editing
    useEffect(() => {
        doRequest(`/ideas/${id}/`, RequestType.Get).then(response => {
            setCurrentData(response.data);
        }).catch(error => {
            navigate(`${APP_PREFIX_PATH}/overview`);
        });
    }, [id]);

    if (isLoading || !currentData) {
        return (
            <>
                <PageHeader title={<FormattedMessage id="idea"  />} />
                <Loader />
            </>
        );
    }

    let comments: ReactNode|ReactNode[] = <Empty />;

    if (currentData.comments.length > 0) {
        comments = currentData.comments.map(comment => <Comment key={comment.pk} author={comment.author ? `${comment.author.first_name} ${comment.author.last_name}` : <FormattedMessage id="deleted-user" />} content={comment.text} datetime={
            <Tooltip title={<FormattedDate value={moment(comment.created_at).toDate()} day="2-digit" month="long" year="numeric" hour="2-digit" minute="2-digit" />}>
                <span>{moment(comment.created_at).fromNow()}</span>
            </Tooltip>
        } />);
    }

    return (
        <>
            <PageHeader title={<FormattedMessage id="idea"  />} />
            <Row gutter={[16, 16]}>
                <Col lg={12} xs={24}>
                    <Card title={currentData.title}>
                        <Row gutter={[16, 16]}>
                            {currentData.images.map((image: IdeaImageSingle) => <Col key={image.pk}><Image src={image.bytes_url} height="100px"  /></Col>)}
                        </Row>
                        <p style={{ margin: '10px 0 0 0' }}>{currentData.description}</p>
                    </Card>
                </Col>
                <Col lg={12} xs={24}>
                    <Card title={<FormattedMessage id="information"  />}>
                        <Descriptions column={1} bordered>
                            <DescriptionsItem label={<FormattedMessage id="author"  />}>{currentData.author ? `${currentData.author.first_name} ${currentData.author.last_name}` : <FormattedMessage id="deleted-user" />}</DescriptionsItem>
                            <DescriptionsItem label={<FormattedMessage id="category"  />}>{currentData.category.name}</DescriptionsItem>
                            <DescriptionsItem label={<FormattedMessage id="department"  />}>{currentData.department.name}</DescriptionsItem>
                            <DescriptionsItem label={<FormattedMessage id="created-at"  />}><FormattedDate value={moment(currentData.created_at).toDate()} day="2-digit" month="long" year="numeric" hour="2-digit" minute="2-digit" /></DescriptionsItem>
                        </Descriptions>
                    </Card>
                </Col>
                <Col span={24}>
                    <Card title={<FormattedMessage id="comments" />}>
                        {comments}
                    </Card>
                </Col>
            </Row>
        </>
    );
};

export default IdeasView;