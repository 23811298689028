import { ChangeEvent, useState } from 'react';
import { Input } from 'antd';
import { useIntl } from 'react-intl';

const useSearchFilter = (source: object[], searchableKeys: string[]) => {
    const intl = useIntl();
    const [filter, setFilter] = useState('');

    const onSearchChangeHandler = (event: ChangeEvent<HTMLInputElement>) => {
        setFilter(event.target.value);
    };

    const searchElement = <Input onChange={onSearchChangeHandler} value={filter} placeholder={intl.formatMessage({ id: 'search' })} />;

    const filteredData: any = source.filter((item: any) => {
        let keep = false;

        searchableKeys.forEach((key: string) => {
            const data = item[key] + '';

            if (data.toLowerCase().includes(filter.toLowerCase())) {
                keep = true;
            }
        });

        return keep;
    });

    return [
        searchElement,
        filteredData
    ];
};

export default useSearchFilter;