import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSingle } from '../../api-interfaces/Users';

interface authState {
    user: UserSingle|null
}

const initialState: authState = {
    user: null
};

const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, action: PayloadAction<UserSingle>) => {
            state.user = action.payload;
        },
        logout: (state) => {
            state.user = null;
        },
    }
});

export const authActions = authSlice.actions;
export default authSlice.reducer;