import { Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import { FormattedMessage } from 'react-intl';
import Overview from '../../../components/shared-components/Overview';
import { DepartmentSingle } from '../../../api-interfaces/Departments';

const columns = [
    {
        title: <FormattedMessage id="code" />,
        dataIndex: 'code',
        key: 'code',
        render: (_: string, record: DepartmentSingle) => (
            <Tooltip title={<FormattedMessage id="edit" />}>
                <Link to={`${APP_PREFIX_PATH}/departments/${record.pk}`}>{record.code}</Link>
            </Tooltip>
        )
    },
    {
        title: <FormattedMessage id="name" />,
        dataIndex: 'name',
        key: 'name'
    },
    {
        title: <FormattedMessage id="users" />,
        dataIndex: 'total_users',
        key: 'users',
        responsive: ['sm' as const],
        width: '105px',
    }
];

const Departments = () => {
    return (
        <Overview
            title={<FormattedMessage id="departments.overview" />}
            endpoint="/departments/"
            columns={columns}
            searchKeys={['code', 'name']}
            canCreate
            canDelete />
    );
};

export default Departments;