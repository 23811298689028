import { Layout } from 'antd';
import Logo from '../Logo/Logo';
import ProfileNav from './ProfileNav/ProfileNav';
import classes from './HeaderNav.module.less';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';

interface HeaderNavProps {
    isMobile: boolean,
    toggleMobileNav: () => void
}

const HeaderNav = (props: HeaderNavProps) => {
    return (
        <Layout.Header className={classes.AppHeader}>
            <div className={classes.AppHeaderWrapper}>
                <div className={classes.Nav}>
                    <div className="nav-left">
                        {props.isMobile && <MenuUnfoldOutlined onClick={props.toggleMobileNav} style={{ color: '#fff', fontSize: '18px' }} />}
                        {!props.isMobile && <Link to="/overview"><Logo /></Link>}
                    </div>
                    <ProfileNav />
                </div>
            </div>
        </Layout.Header>
    );
};

export default HeaderNav;