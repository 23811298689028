import useApiRequest, { RequestType } from '../../../hooks/useApiRequest';
import { ReactNode, useEffect, useState } from 'react';
import useSearchFilter from '../../../hooks/useSearchFilter';
import PageHeader from '../../layout-components/PageHeader/PageHeader';
import { Button, message, Space, Table, Tooltip } from 'antd';
import { ColumnsType } from 'antd/lib/table/interface';
import { FormattedMessage, useIntl } from 'react-intl';
import { DeleteOutlined, FolderOutlined, FolderOpenOutlined } from '@ant-design/icons';
import ActionPopconfirm from '../ActionPopconfirm';
import { Default } from '../../../api-interfaces/Default';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import { Link } from 'react-router-dom';

interface extraAction<T> {
    key: string|number,
    label: string|ReactNode,
    icon: ReactNode,
    onClick: (record: T) => void
}

interface Props {
    columns: ColumnsType<any>,
    title: string|ReactNode,
    endpoint: string,
    endpointOptions?: Record<string, string>,
    searchKeys: string[],
    canCreate?: boolean,
    canDelete?: boolean,
    canArchive?: boolean,
    canRestore?: boolean,
    extraTopItems?: ReactNode|ReactNode[],
    extraActionItems?: extraAction<any>[]
}

const Overview = (props: Props) => {
    const intl = useIntl();
    const { isLoading, doRequest } = useApiRequest();
    const [data, setData] = useState<Default[]>([]);
    const [searchElement, filteredData] = useSearchFilter(data, props.searchKeys);
    const columns = [...props.columns];
    const hasActions = props.extraActionItems || props.canDelete || props.canArchive || props.canRestore;

    // On Mount
    useEffect(() => {
        const params = new URLSearchParams(props.endpointOptions).toString();

        doRequest(`${props.endpoint}?${params}`, RequestType.Get).then(response => {
            setData(response.data);
        });
    }, [props.endpointOptions]);

    const onDeleteHandler = (id: number) => {
        doRequest(`${props.endpoint}${id}`, RequestType.Delete).then(response => {
            setData(prev => {
                return prev.filter(item => item.pk !== id);
            });

            message.success(intl.formatMessage({ id: 'message.success.deleted' }));
        }).catch(() => {
            message.error(intl.formatMessage({ id: 'message.error.deleted' }));
        });
    };

    const onArchiveHandler = (id: number) => {
        doRequest(`${props.endpoint}${id}/archive/`, RequestType.Post).then(response => {
            setData(prev => {
                return prev.filter(item => item.pk !== id);
            });

            message.success(intl.formatMessage({ id: 'message.success.archived' }));
        }).catch(() => {
            message.error(intl.formatMessage({ id: 'message.error.archived' }));
        });
    };

    const onRestoreHandler = (id: number) => {
        doRequest(`${props.endpoint}${id}/restore/`, RequestType.Post).then(response => {
            setData(prev => {
                return prev.filter(item => item.pk !== id);
            });

            message.success(intl.formatMessage({ id: 'message.success.restored' }));
        }).catch(() => {
            message.error(intl.formatMessage({ id: 'message.error.restored' }));
        });
    };

    // Set-up Actions column
    if (hasActions) {
        columns.push({
            title: <FormattedMessage id="actions" />,
            key: 'action',
            width: '80px',
            render: (text: string, record: any) => {
                let extraActions = null;

                if (props.extraActionItems) {
                    extraActions = props.extraActionItems.map(action => (
                        <ActionPopconfirm key={action.key} onConfirm={() => action.onClick(record)}>
                            <Tooltip title={action.label}>
                                {action.icon}
                            </Tooltip>
                        </ActionPopconfirm>
                    ));
                }

                return (
                    <Space size="middle" key={record.id}>
                        {extraActions}
                        {props.canDelete &&
                            <ActionPopconfirm onConfirm={() => onDeleteHandler(record.pk)}>
                                <Tooltip title={<FormattedMessage id="delete" />}>
                                    <DeleteOutlined />
                                </Tooltip>
                            </ActionPopconfirm>}
                        {props.canArchive &&
                            <ActionPopconfirm onConfirm={() => onArchiveHandler(record.pk)}>
                                <Tooltip title={<FormattedMessage id="archive" />}>
                                    <FolderOutlined />
                                </Tooltip>
                            </ActionPopconfirm>}
                        {props.canRestore &&
                            <ActionPopconfirm onConfirm={() => onRestoreHandler(record.pk)}>
                                <Tooltip title={<FormattedMessage id="restore" />}>
                                    <FolderOpenOutlined />
                                </Tooltip>
                            </ActionPopconfirm>}
                    </Space>
                );
            }
        });
    }

    const topActions = (
        <>
            {searchElement}
            {props.canCreate && <Link to={`${APP_PREFIX_PATH}${props.endpoint}create`}><Button type="primary"><FormattedMessage id="create" /></Button></Link>}
            {props.extraTopItems}
        </>
    );

    // Return Overview Page
    return (
        <>
            <PageHeader title={props.title} extra={topActions} />
            <Table loading={isLoading} columns={columns} dataSource={filteredData} rowKey="pk" sticky />
        </>
    );
};

export default Overview;