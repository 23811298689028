
import React, { Suspense } from 'react';
import { Route, Routes } from 'react-router-dom';
import PageHeader from '../../components/layout-components/PageHeader/PageHeader';
import News from './News';

const BizplayViews = () => {
    return (
        <Suspense fallback={<PageHeader title="" />}>
            <Routes>
                <Route path="news" element={<News />}/>
            </Routes>
        </Suspense>
    );
};

export default React.memo(BizplayViews);
