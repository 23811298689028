import { Row, Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import classes from './Loader.module.less';

interface Props {
    size?: number
}

const Loader = (props: Props) => (
    <Row className={classes.Loader} justify="center">
        <Spin indicator={<LoadingOutlined style={{ fontSize: props.size ?? 55 }} />} />
    </Row>
);

export default Loader;