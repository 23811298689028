import { Drawer } from 'antd';
import Menu from '../../shared-components/Menu/Menu';

interface MobileNavProps {
    visible: boolean,
    toggleMobileNav: () => void
}

const MobileNav = (props: MobileNavProps) => {
    return (
        <Drawer
            placement="left"
            visible={props.visible}
            bodyStyle={{ padding: 0, backgroundColor: '#3d4d90' }}
            headerStyle={{ paddingBottom: '15px', backgroundColor: '#3d4d90' }}
            onClose={props.toggleMobileNav}>
            <Menu toggleMobileNav={props.toggleMobileNav} />
        </Drawer>
    );
};

export default MobileNav;