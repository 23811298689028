import { Button, Card, Col, Form, Row } from 'antd';
import Input from 'antd/lib/input/Input';
import PageHeader from '../../../components/layout-components/PageHeader/PageHeader';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useCreateAndUpdate from '../../../hooks/useCreateAndUpdate';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import { DepartmentSingle } from '../../../api-interfaces/Departments';
import Loader from '../../../components/layout-components/Loader/Loader';

const CreateOrUpdateDepartment = () => {
    const navigate = useNavigate();

    const { id, currentData, isLoading, form, onFinishHandler } = useCreateAndUpdate<DepartmentSingle>('/departments/', () => {
        navigate(`${APP_PREFIX_PATH}/departments/overview`);
    });

    // Wait until all necessary data is loaded
    if (id && !currentData) {
        return (
            <>
                <PageHeader title={id ? <FormattedMessage id="departments.edit"  /> : <FormattedMessage id="departments.create" /> } />
                <Loader />
            </>
        );
    }

    return (
        <>
            <PageHeader title={id ? <FormattedMessage id="departments.edit"  /> : <FormattedMessage id="departments.create" /> } />

            <Form layout="vertical" form={form} onFinish={onFinishHandler} initialValues={{
                code: currentData?.code ?? '',
                name: currentData?.name ?? ''
            }}>
                <Card>
                    <Row gutter={[15, 0]}>
                        <Col span={24}>
                            <Form.Item name="code" label={<FormattedMessage id="code" />} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="name" label={<FormattedMessage id="name" />} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    <FormattedMessage id="submit" />
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </>
    );
};

export default CreateOrUpdateDepartment;