import { Tooltip } from 'antd';
import { FormattedMessage } from 'react-intl';
import Overview from '../../../../components/shared-components/Overview';
import { NewsPostCategorySingle } from '../../../../api-interfaces/News';
import { APP_PREFIX_PATH } from '../../../../constants/AppConstants';
import { Link } from 'react-router-dom';

const columns = [
    {
        title: <FormattedMessage id="title" />,
        dataIndex: 'name',
        key: 'name',
        render: (_: string, record: NewsPostCategorySingle) => (
            <Tooltip title="Edit">
                <Link to={`${APP_PREFIX_PATH}/news/categories/${record.pk}`}>{record.name}</Link>
            </Tooltip>
        )
    },
    {
        title: <FormattedMessage id="posts" />,
        dataIndex: 'newspost_set',
        key: 'posts',
        render: (set: number[]) => set.length
    }
];

const NewsCategories = () => {
    return (
        <Overview
            title={<FormattedMessage id="news.categories.overview" />}
            endpoint="/news/categories/"
            columns={columns}
            searchKeys={['name']}
            canCreate
            canDelete />
    );
};

export default NewsCategories;