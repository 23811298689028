import React from 'react';
import { Navigate, Route, Routes } from 'react-router-dom';
import Login from './login';

const AuthViews = () => {
    return (
        <Routes>
            <Route path="login" element={<Login />}/>
            <Route path="*" element={<Navigate to='login' replace />} />
        </Routes>
    );
};

export default AuthViews;