import { AxiosResponse } from 'axios';
import { useCallback, useState } from 'react';
import http from '../services/http';

export enum RequestType {
    Get,
    Post,
    Patch,
    Put,
    Delete,
    Download
}

interface ApiRequestHook {
    isLoading: boolean,
    error: null|any,
    doRequest: (url: string, type: RequestType, data?: object) => Promise<AxiosResponse>
}

const useApiRequest = (): ApiRequestHook => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [error, setError] = useState<any|null>(null);

    const handleDownload = useCallback((url: string, data: any) => {
        return new Promise<AxiosResponse>((resolve, reject) => {
            http.get(url, {
                responseType: 'blob'
            }).then(response => {
                const url = window.URL.createObjectURL(new Blob([response.data]));
                const link = document.createElement('a');

                link.href = url;
                link.setAttribute('download', data?.name);
                document.body.appendChild(link);
                link.click();

                resolve(response);
            }).catch(error => {
                reject(error);
            }).finally(() => {
                setIsLoading(false);
            });
        });
    }, []);

    const handleResponse = useCallback((request: Promise<AxiosResponse>) => {
        return new Promise<AxiosResponse>((resolve, reject) => {
            request.then(response => {
                resolve(response);
            }).catch(error => {
                if (error.response && error.response.data.non_field_errors) {
                    setError(Object.values(error.response.data.non_field_errors)[0]);
                } else  {
                    setError(error);
                }

                reject(error);
            }).finally(() => {
                setIsLoading(false);
            });
        });
    }, []);

    const doRequest = useCallback((url: string, type: RequestType, data?: object): Promise<AxiosResponse> => {
        setIsLoading(true);
        setError(null);

        switch (type) {
        case RequestType.Get:
            return handleResponse(http.get(url));
        case RequestType.Post:
            return handleResponse(http.post(url, data));
        case RequestType.Patch:
            return handleResponse(http.patch(url, data));
        case RequestType.Put:
            return handleResponse(http.put(url, data));
        case RequestType.Delete:
            return handleResponse(http.delete(url));
        case RequestType.Download:
            return handleDownload(url, data);
        default:
            setError({ msg: 'Request method not found!' });
            return Promise.reject('Request method not found!');
        }
    }, [handleResponse]);

    return {
        isLoading,
        error,
        doRequest
    };
};

export default useApiRequest;