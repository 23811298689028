import { Card, Col, Statistic, Tooltip } from 'antd';
import { RiseOutlined, FallOutlined, StockOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import React, { ReactNode } from 'react';

interface Props {
    totalValue: number,
    lastWeekValue: number,
    title?: ReactNode,
    tooltip?: ReactNode,
    link?: string
}

const CountBlock = (props: Props) => {
    let icon = <RiseOutlined />;
    let color = '#4fc248';

    if (props.lastWeekValue === 0) {
        icon = <StockOutlined />;
        color = '#d5aa30';
    } else if (props.lastWeekValue < 0) {
        icon = <FallOutlined />;
        color = '#d53030';
    }

    let card = (
        <Card>
            <Statistic
                title={props.title}
                value={props.lastWeekValue}
                valueStyle={{ color: color }}
                prefix={icon}
            />
        </Card>
    );

    card = props.tooltip ? <Tooltip title={props.tooltip} placement="bottom">{card}</Tooltip> : card;

    card = props.link ? <Link to={props.link}>{card}</Link> : card;

    return (
        <Col span={24} sm={12} lg={6}>{card}</Col>
    );
};

export default CountBlock;