import { ScreenMap } from 'antd/lib/_util/responsiveObserve';
import { UploadFile } from 'antd/lib/upload/interface';

class Utils {
    static getBreakPoint(screens: ScreenMap): string[] {
        const breakpoints = [];

        for (const [key, value] of Object.entries(screens)) {
            if (value) {
                breakpoints.push(key);
            }
        }

        return breakpoints;
    }

    static fileToBase64(file: UploadFile<File>): Promise<string>
    {
        return new Promise((resolve, reject) => {
            const reader = new FileReader();
            reader.readAsDataURL(file as unknown as File);
            reader.onload = () => resolve(reader.result as string);
            reader.onerror = error => reject(error);
        });
    }
}

export default Utils;