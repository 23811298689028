import classes from './Logo.module.less';

const Logo = () => {
    return (
        <div className={classes.Logo}>
            <img src="/app-logo-white.png" alt="logo" />
        </div>
    );
};

export default Logo;