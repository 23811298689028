import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import moment from 'moment';
import { Provider } from 'react-redux';
import store from './store';

moment.locale('en', {
    week: {
        dow: 1,
    },
});

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);

root.render(<Provider store={store}><App /></Provider>);
