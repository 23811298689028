import {
    DashboardOutlined,
    TeamOutlined,
    GroupOutlined,
    FileTextOutlined,
    BulbOutlined
} from '@ant-design/icons';
import { ItemType } from 'antd/lib/menu/hooks/useItems';
import { FormattedMessage } from 'react-intl';

const NavigationConfig: ItemType[] = [
    {
        key: 'overview',
        label: <FormattedMessage id="overview" />,
        icon: <DashboardOutlined />
    },
    {
        key: 'users',
        label: <FormattedMessage id="users" />,
        icon: <TeamOutlined />,
        children: [
            {
                key: 'users/overview',
                label: <FormattedMessage id="users.overview" />
            },
            {
                key: 'users/create',
                label: <FormattedMessage id="users.create" />
            },
            {
                key: 'users/bulk/add',
                label: <FormattedMessage id="users.bulk-add" />
            }
        ]
    },
    {
        key: 'departments',
        label: <FormattedMessage id="departments" />,
        icon: <GroupOutlined />,
        children: [
            {
                key: 'departments/overview',
                label: <FormattedMessage id="departments.overview" />
            },
            {
                key: 'departments/create',
                label: <FormattedMessage id="departments.create" />
            }
        ]
    },
    {
        key: 'news',
        label: <FormattedMessage id="news-posts" />,
        icon: <FileTextOutlined />,
        children: [
            {
                key: 'news/overview',
                label: <FormattedMessage id="news.overview" />
            },
            {
                key: 'news/create',
                label: <FormattedMessage id="news.create" />
            },
            {
                key: 'news/archived',
                label: <FormattedMessage id="news.archived" />
            },
            {
                key: 'news/categories',
                label: <FormattedMessage id="categories" />
            }
        ]
    },
    {
        key: 'ideas',
        label: <FormattedMessage id="ideas" />,
        icon: <BulbOutlined />,
        children: [
            {
                key: 'ideas/overview',
                label: <FormattedMessage id="ideas.overview" />
            },
            {
                key: 'ideas/archived',
                label: <FormattedMessage id="ideas.archived" />
            },
            {
                key: 'ideas/categories',
                label: <FormattedMessage id="categories" />
            }
        ]
    }
];

export default NavigationConfig;