import React from 'react';
import './App.less';
import Index from './views';
import { BrowserRouter } from 'react-router-dom';

const App = () => {
    return (
        <div className="App">
            <BrowserRouter>
                <Index />
            </BrowserRouter>
        </div>
    );
};

export default App;
