import { Alert, Button, Card, Checkbox, Col, Form, Row, Select } from 'antd';
import Input from 'antd/lib/input/Input';
import PageHeader from '../../../components/layout-components/PageHeader/PageHeader';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useApiRequest, { RequestType } from '../../../hooks/useApiRequest';
import { useEffect, useState } from 'react';
import { UserSingle } from '../../../api-interfaces/Users';
import Loader from '../../../components/layout-components/Loader/Loader';
import { DepartmentSingle } from '../../../api-interfaces/Departments';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import useCreateAndUpdate from '../../../hooks/useCreateAndUpdate';

const CreateOrUpdateUser = () => {
    const navigate = useNavigate();
    const [departments, setDepartments] = useState<DepartmentSingle[]|null>(null);
    const { doRequest } = useApiRequest();

    const { id, currentData, isLoading, form, onFinishHandler } = useCreateAndUpdate<UserSingle>('/users/', () => {
        navigate(`${APP_PREFIX_PATH}/users/overview`);
    });

    // Load departments for dropdown
    useEffect(() => {
        doRequest('/departments', RequestType.Get).then(response => {
            setDepartments(response.data);
        });
    }, [doRequest]);

    // Wait until all necessary data is loaded
    if ((id && !currentData) || !departments) {
        return (
            <>
                <PageHeader title={id ? <FormattedMessage id="users.edit"  /> : <FormattedMessage id="users.create" /> } />
                <Loader />
            </>
        );
    }

    return (
        <>
            <PageHeader title={id ? <FormattedMessage id="users.edit" /> : <FormattedMessage id="users.create" /> } />
            <Form layout="vertical" form={form} onFinish={onFinishHandler} initialValues={{
                first_name: currentData?.first_name ?? '',
                last_name: currentData?.last_name ?? '',
                email: currentData?.email ?? '',
                is_staff: currentData?.is_staff ?? false,
                department: currentData?.department?.pk ?? null,
            }}>
                <Card>
                    {!currentData && <Alert type="warning" style={{ marginBottom: '15px' }} message={<FormattedMessage id="warning" />} description={<FormattedMessage id="users.create.warning" />} showIcon />}
                    <Row gutter={[15, 0]}>
                        <Col span={24} sm={12}>
                            <Form.Item name="first_name" label={<FormattedMessage id="firstname" />} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} sm={12}>
                            <Form.Item name="last_name" label={<FormattedMessage id="lastname" />} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="email" label={<FormattedMessage id="email" />} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="is_staff" valuePropName="checked">
                                <Checkbox><FormattedMessage id="users.dashboard-access" /></Checkbox>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item name="department" label={<FormattedMessage id="department" />} rules={[{ required: true }]}>
                                <Select
                                    placeholder={<FormattedMessage id="make-a-choice" />}
                                >
                                    {departments.map(department => <Select.Option key={department.pk} value={department.pk}>{department.name}</Select.Option>)}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button loading={isLoading} type="primary" htmlType="submit">
                                    <FormattedMessage id="submit" />
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </>
    );
};

export default CreateOrUpdateUser;