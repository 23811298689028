import { ConfigProvider } from 'antd';
import { useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';
import { Navigate, Route, Routes, useLocation } from 'react-router-dom';
import { Spinner } from '../components/util-components/Spinner/Spinner';
import { APP_PREFIX_PATH, AUTH_PREFIX_PATH, BIZPLAY_PREFIX_PATH } from '../constants/AppConstants';
import useApiRequest, { RequestType } from '../hooks/useApiRequest';
import useAppDispatch from '../hooks/useAppDispatch';
import useAppSelector from '../hooks/useAppSelector';
import Locale from '../lang';
import AppLayout from '../layouts/app-layout';
import AuthLayout from '../layouts/auth-layout';
import BizplayLayout from '../layouts/bizplay-layout';
import { authActions } from '../store/slices/authSlice';

const CASRedirect = () => {
    useEffect(() => {
        const pathArray = (process.env.REACT_APP_API_ENDPOINT ?? '').split('/');
        const protocol = pathArray[0];
        const host = pathArray[2];
        const url = `${protocol}//${host}`;

        window.location.href = `${url}/cas/login/?next=${window.location.protocol}//${window.location.host}`;
    }, []);

    return (<h1>redirecting...</h1>);
};

const Index = () => {
    const locale = useAppSelector(state => state.theme.locale);
    const user = useAppSelector(state => state.auth.user);
    const currentLocale = Locale[locale];
    const { doRequest } = useApiRequest();
    const dispatch = useAppDispatch();
    const [isInitialized, setIsInitialized] = useState(false);
    const location = useLocation();

    useEffect(() => {
        if (location.pathname.toLowerCase().includes('/bizplay')) {
            setIsInitialized(true);
            return;
        }

        doRequest('/auth/me/', RequestType.Get).then((response) => {
            dispatch({
                type: authActions.login,
                payload: response.data
            });
        }).finally(() => {
            setIsInitialized(true);
        });
    }, [doRequest]);

    if (!isInitialized) {
        return <Spinner />;
    }

    return (
        <IntlProvider locale={currentLocale.locale} messages={currentLocale.messages}>
            <ConfigProvider locale={currentLocale.antd}>
                <Routes>
                    <Route path={`${BIZPLAY_PREFIX_PATH}/*`} element={<BizplayLayout />} />
                    {process.env.REACT_APP_ENVIRONMENT === 'development' && <Route path="/cas/login/*" element={<CASRedirect />} />}
                    {user && <Route path={`${APP_PREFIX_PATH}/*`} element={<AppLayout />} />}
                    {!user && <Route path={`${AUTH_PREFIX_PATH}/*`} element={<AuthLayout />} />}
                    <Route path="*" element={<Navigate to={ user ? APP_PREFIX_PATH : AUTH_PREFIX_PATH } replace />} />
                </Routes>
            </ConfigProvider>
        </IntlProvider>
    );
};

export default Index;