import { createSlice, PayloadAction } from '@reduxjs/toolkit';

const localeSelected: string = localStorage.getItem('locale') ?? 'nl';

const themeSlice = createSlice({
    name: 'theme',
    initialState: {
        locale: localeSelected
    },
    reducers: {
        changeLocale: (state, action: PayloadAction<string>) => {
            localStorage.setItem('locale', action.payload);
            state.locale = action.payload;
        }
    }
});

export const themeActions = themeSlice.actions;
export default themeSlice.reducer;