import { Button, Card, Checkbox, Col, DatePicker, Form, message, Row, Select, Upload } from 'antd';
import Input from 'antd/lib/input/Input';
import moment from 'moment';
import * as React from 'react';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, useNavigate } from 'react-router-dom';
import { DepartmentSingle } from '../../../api-interfaces/Departments';
import { NewsPostCategorySingle, NewsPostSingle } from '../../../api-interfaces/News';
import Loader from '../../../components/layout-components/Loader/Loader';
import PageHeader from '../../../components/layout-components/PageHeader/PageHeader';
import CKEditor from '../../../components/shared-components/CKEditor/CKEditor';
import { APP_PREFIX_PATH, BIZPLAY_PREFIX_PATH } from '../../../constants/AppConstants';
import useApiRequest, { RequestType } from '../../../hooks/useApiRequest';
import useCreateAndUpdate from '../../../hooks/useCreateAndUpdate';
import { UploadFile } from 'antd/lib/upload/interface';
import Utils from '../../../utils';
import { RcFile } from 'antd/es/upload';

const CreateOrUpdateNews = () => {
    const { doRequest } = useApiRequest();
    const navigate = useNavigate();
    const [departments, setDepartments] = useState<DepartmentSingle[] | null>(null);
    const [categories, setCategories] = useState<NewsPostCategorySingle[] | null>(null);
    const [images, setImages] = useState<UploadFile[]>([]);
    const [imagesToDelete, setImagesToDelete] = useState<string[]>([]);
    const [documents, setDocuments] = useState<UploadFile[]>([]);
    const [documentsToDelete, setDocumentsToDelete] = useState<string[]>([]);
    const [formValues, setFormValues] = useState<NewsPostSingle>();

    const processImagesAndDocuments = (record: NewsPostSingle) => {
        // Upload images
        for (let i = 0; i < images.length; i++) {
            const image = images[i];

            if (image.status === 'done') {
                continue;
            }

            const data = new FormData();
            data.append('post', `${record.pk}`);
            data.append('title', `${image.name}`);
            data.append('file', image as RcFile);
            doRequest('/news/images/', RequestType.Post, data);
        }

        // Upload documents
        for (let i = 0; i < documents.length; i++) {
            const document = documents[i];

            if (document.status === 'done') {
                continue;
            }

            const data = new FormData();
            data.append('post', `${record.pk}`);
            data.append('title', `${document.name}`);
            data.append('document', document as RcFile);
            doRequest('/news/documents/', RequestType.Post, data);
        }

        // Delete images
        for (let i = 0; i < imagesToDelete.length; i++) {
            doRequest(`/news/images/${imagesToDelete[i]}/`, RequestType.Delete);
        }

        // Delete documents
        for (let i = 0; i < documentsToDelete.length; i++) {
            doRequest(`/news/documents/${documentsToDelete[i]}/`, RequestType.Delete);
        }

        setImagesToDelete([]);
        setDocumentsToDelete([]);

        navigate(`${APP_PREFIX_PATH}/news/overview`);
    };

    const { id, currentData, isLoading, form, onFinishHandler } = useCreateAndUpdate<NewsPostSingle>('/news/', processImagesAndDocuments);

    useEffect(() => {
        doRequest('/departments/', RequestType.Get).then(response => {
            setDepartments(response.data);
        });

        doRequest('/news/categories/', RequestType.Get).then(response => {
            setCategories(response.data);
        });
    }, [doRequest]);

    useEffect(() => {
        if (!currentData) return;

        const initialImages: UploadFile[] = [];
        const initialDocuments: UploadFile[] = [];

        currentData.image_set.forEach(image => {
            initialImages.push({
                uid: `${image.pk}`,
                name: image.title,
                status: 'done',
                url: image.bytes_url,
                thumbUrl: image.bytes_url
            });
        });

        currentData.documents.forEach(document => {
            initialDocuments.push({
                uid: `${document.pk}`,
                name: document.title,
                status: 'done',
            });
        });

        setImages(initialImages);
        setDocuments(initialDocuments);
        setFormValues(currentData);
    }, [currentData]);

    // Wait until all necessary data is loaded
    if ((id && !currentData) || !departments || !categories) {
        return (
            <>
                <PageHeader title={id ? <FormattedMessage id="news.edit" /> : <FormattedMessage id="news.create" />} />
                <Loader />
            </>
        );
    }

    const addImage = async (image: UploadFile<File>) => {
        const base64 = await Utils.fileToBase64(image);

        image.url = base64;
        image.thumbUrl = base64;
        setImages(prev => [...prev, image]);
    };

    const addDocument = async (document: UploadFile<File>) => {
        const base64 = await Utils.fileToBase64(document);

        document.url = base64;
        document.thumbUrl = base64;
        setDocuments(prev => [...prev, document]);
    };

    const beforeImageUploadHandler = (image: UploadFile): boolean => {
        if (!image.type?.toString().includes('image/')) {
            message.warn(`${image.name} is not an image`);
        } else {
            addImage(image);
        }

        return false;
    };

    const beforeDocumentUploadHandler = (document: UploadFile): boolean => {
        if (!document.type?.toString().includes('pdf')) {
            message.warn(`${document.name} is not a PDF`);
        } else {
            addDocument(document);
        }

        return false;
    };

    const onRemoveImageHandler = (image: UploadFile): boolean => {
        setImages(prev => prev.filter((item) => image.uid !== item.uid));

        if (image.status !== 'done') {
            return true;
        }

        setImagesToDelete(prev => [...prev, image.uid]);

        return true;
    };

    const onRemoveDocumentHandler = (document: UploadFile): boolean => {
        setDocuments(prev => prev.filter((item) => document.uid !== item.uid));

        if (document.status !== 'done') {
            return true;
        }

        setDocumentsToDelete(prev => [...prev, document.uid]);

        return true;
    };

    return (
        <>
            <PageHeader title={id ? <FormattedMessage id="news.edit" /> : <FormattedMessage id="news.create" />} />
            <Form layout="vertical" form={form} onFinish={onFinishHandler} onValuesChange={(_, all: NewsPostSingle) => setFormValues(all)} initialValues={{
                title: currentData?.title ?? '',
                category: currentData?.category.pk ?? null,
                text: currentData?.text ?? '',
                show_on_bizplay_screen: currentData?.show_on_bizplay_screen ?? '',
                bizplay_description: currentData?.bizplay_description ?? '',
                departments: currentData?.departments.map((department: DepartmentSingle) => department.pk) ?? [],
                publish_date: currentData?.publish_date ? moment(currentData.publish_date) : null,
                publish_until: currentData?.publish_until ? moment(currentData.publish_until) : null,
            }}>
                <Card>
                    <Row gutter={[15, 0]}>
                        <Col span={24} lg={18}>
                            <Row gutter={[15, 0]}>
                                <Col span={24}>
                                    <Form.Item name="title" label={<FormattedMessage id="title" />} rules={[{ required: true }]}>
                                        <Input />
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <Form.Item name="category" label={<FormattedMessage id="category" />} rules={[{ required: true }]}>
                                        <Select placeholder={<FormattedMessage id="category-select" />}>
                                            {categories.map(category => <Select.Option key={category.pk} value={category.pk}>{category.name}</Select.Option>)}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={24}>
                                    <CKEditor name="text" label={<FormattedMessage id="message" />} rules={[{ required: true }]} />
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="publish_date" label={<FormattedMessage id="news.form.publish-from" />} rules={[{ required: true }]}>
                                        <DatePicker style={{ width: '100%' }} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
                                    </Form.Item>
                                </Col>
                                <Col span={12}>
                                    <Form.Item name="publish_until" label={<FormattedMessage id="news.form.publish-until" />} rules={[{ required: true }]}>
                                        <DatePicker style={{ width: '100%' }} showTime={{ format: 'HH:mm' }} format="YYYY-MM-DD HH:mm" />
                                    </Form.Item>
                                </Col>
                            </Row>
                        </Col>
                        <Col span={24} lg={6}>
                            <Col span={24}>
                                <Form.Item name="departments" label={<FormattedMessage id="departments" />}>
                                    <Checkbox.Group>
                                        <Row>
                                            {departments.map(department => (
                                                <Col span={24} key={department.pk}>
                                                    <Checkbox value={department.pk}>{department.name}</Checkbox>
                                                </Col>
                                            ))}
                                        </Row>
                                    </Checkbox.Group>
                                </Form.Item>
                            </Col>
                            <Col span={24}>
                                <Upload listType="picture"  beforeUpload={beforeImageUploadHandler}
                                    onRemove={onRemoveImageHandler} fileList={images} showUploadList={{ showPreviewIcon: false }}>
                                    {images.length < 3 && (<div>
                                        <Button type="default"><FormattedMessage id="upload-image" /></Button>
                                    </div>)}
                                </Upload>
                            </Col>
                            <Col span={24} style={{ marginTop: '15px' }}>
                                <Upload listType="picture"  beforeUpload={beforeDocumentUploadHandler}
                                    onRemove={onRemoveDocumentHandler} fileList={documents} showUploadList={{ showPreviewIcon: false }}>
                                    {documents.length < 3 && (<div>
                                        <Button type="default"><FormattedMessage id="upload-document" /></Button>
                                    </div>)}
                                </Upload>
                            </Col>
                            <Col style={{ marginTop: '15px' }}>
                                <Form.Item name="show_on_bizplay_screen" valuePropName="checked" label={<FormattedMessage id="news.form.show-on-screen" />}>
                                    <Checkbox />
                                </Form.Item>

                                { formValues?.show_on_bizplay_screen && currentData?.pk &&
                                    <Link to={`${BIZPLAY_PREFIX_PATH}/news?pk=${ currentData?.pk }`}>Preview</Link> }
                                { formValues?.show_on_bizplay_screen &&
                                    <CKEditor name="bizplay_description" label={<FormattedMessage id="news.form.screen-message" />} rules={[{ required: false }]} />
                                }

                            </Col>
                        </Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    <FormattedMessage id="submit" />
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </>
    );
};

export default CreateOrUpdateNews;