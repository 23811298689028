import { PageHeader as PageHeaderAntd } from 'antd';
import { ReactNode } from 'react';

interface PageHeaderProps {
    title: string|ReactNode,
    extra?: ReactNode
}

const PageHeader = (props: PageHeaderProps) => {
    return (
        <PageHeaderAntd
            extra={props.extra}
            title={props.title}
            ghost={false}
            style={{ margin: '-15px', marginBottom: '15px' }}/>
    );
};

export default PageHeader;