import { Layout } from 'antd';
import HeaderNav from '../../components/layout-components/HeaderNav/HeaderNav';
import SideNav from '../../components/layout-components/SideNav/SideNav';
import React, { useState } from 'react';
import AppViews from '../../views/app-views';
import useBreakpoint from 'antd/lib/grid/hooks/useBreakpoint';
import Utils from '../../utils';
import MobileNav from '../../components/layout-components/MobileNav/MobileNav';

const Index = () => {
    const [mobileNavOpen, setMobileNavOpen] = useState(false);
    const breakPoints = Utils.getBreakPoint(useBreakpoint());
    const isMobile = !breakPoints.includes('md');

    const onToggleMobileNavHandler = () => {
        setMobileNavOpen((open) => !open);
    };

    return (
        <Layout style={{ minHeight: '100vh' }}>
            <HeaderNav isMobile={isMobile} toggleMobileNav={onToggleMobileNavHandler} />
            <Layout>
                {!isMobile && <SideNav />}
                {isMobile && <MobileNav visible={mobileNavOpen} toggleMobileNav={onToggleMobileNavHandler} />}
                <Layout.Content style={{ padding: '15px' }}>
                    <AppViews />
                </Layout.Content>
            </Layout>
        </Layout>
    );
};

export default React.memo(Index);