import Chart from 'react-apexcharts';
import { Card } from 'antd';
import { useEffect, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import useApiRequest, { RequestType } from '../../../../hooks/useApiRequest';
import { Statistics } from '../../../../api-interfaces/Statistics';

const chartOptions = {
    colors: ['#0c2074', '#3ea13e', '#d5b509'],
    chart: {
        id: 'user-statistics',
        stacked: true
    },
    plotOptions: {
        bar: {
            horizontal: false,
            borderRadius: 5
        }
    },
    legend: {
        show: true,
        position: 'bottom' as const
    },
    tooltip: {
        intersect: false,
        shared: true
    },
    xaxis: {
        categories: ['']
    },
    yaxis: {
        decimalsInFloat: 0
    }
};

const UserStatistics = () => {
    const { doRequest } = useApiRequest();
    const [data, setData] = useState<Statistics|null>(null);

    useEffect(() => {
        doRequest('/stats/users-growth-plot-data/', RequestType.Get).then(response => {
            setData(response.data);
        });
    }, [doRequest]);

    const options = chartOptions;
    options.xaxis.categories = data?.dates ?? [];

    return (
        <Card title={<FormattedMessage id="overview.stats.user" />} loading={!data}>
            <Chart
                height="500px"
                options={options}
                series={data?.series ?? []}
                type="bar"
            />
        </Card>
    );
};

export default UserStatistics;