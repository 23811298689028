import { configureStore } from '@reduxjs/toolkit';
import authSlice from './slices/authSlice';
import themeSliceReducer from './slices/themeSlice';

const store = configureStore({
    reducer: {
        theme: themeSliceReducer,
        auth: authSlice,
    },
    middleware: getDefaultMiddleware => getDefaultMiddleware({ serializableCheck: false })
});

export default store;
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;