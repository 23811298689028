import { SIDE_NAV_WIDTH } from '../../../constants/ThemeConstant';
import { Affix, Layout, Space } from 'antd';
import Menu from '../../shared-components/Menu/Menu';
import classes from './SideNav.module.less';
import { FormattedMessage } from 'react-intl';
import useAppDispatch from '../../../hooks/useAppDispatch';
import { themeActions } from '../../../store/slices/themeSlice';

const SideNav = () => {
    const dispatch = useAppDispatch();

    const onSwitchLanguageHandler = (locale: string) => {
        dispatch({
            type: themeActions.changeLocale,
            payload: locale
        });
    };

    return (
        <Layout.Sider width={SIDE_NAV_WIDTH}>
            <Affix>
                <Menu />
            </Affix>
            <div className={classes.BottomInfo}>
                <h3><FormattedMessage id="sidebar.questions" /></h3>
                <p><FormattedMessage id="sidebar.support" values={{
                    mail: <a href="mailto:support@ytec.nl" target="_blank">support@ytec.nl</a>
                }} /></p>
                <div>
                    <Space>
                        <FormattedMessage id="language" />
                        <img alt="English" src="/eng.png" width="35px" onClick={() => onSwitchLanguageHandler('en')} />
                        <img alt="Nederlands" src="/nl.svg.webp" width="35px" onClick={() => onSwitchLanguageHandler('nl')} />
                    </Space>
                </div>
            </div>
        </Layout.Sider>
    );
};

export default SideNav;