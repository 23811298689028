import { Button, Card, Col, Form, message, Row, Upload } from 'antd';
import Input from 'antd/lib/input/Input';
import PageHeader from '../../../../components/layout-components/PageHeader/PageHeader';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';
import useCreateAndUpdate from '../../../../hooks/useCreateAndUpdate';
import Loader from '../../../../components/layout-components/Loader/Loader';
import { NewsPostCategorySingle } from '../../../../api-interfaces/News';
import { RcFile, UploadFile } from 'antd/lib/upload/interface';
import Utils from '../../../../utils';
import { useEffect, useState } from 'react';
import useApiRequest, { RequestType } from '../../../../hooks/useApiRequest';
import { APP_PREFIX_PATH } from '../../../../constants/AppConstants';

const CreateOrUpdateNewsCategories = () => {
    const navigate = useNavigate();
    const [singleImage, setSingleImage] = useState<UploadFile[]>([]);
    const { doRequest } = useApiRequest();

    const addImage = async (image: UploadFile<File>) => {
        const base64 = await Utils.fileToBase64(image);

        image.url = base64;
        image.thumbUrl = base64;
        setSingleImage([image]);
    };

    const beforeImageUploadHandler = (image: UploadFile): boolean => {
        if (!image.type?.toString().includes('image/')) {
            message.warn(`${image.name} is not an image`);
        } else {
            addImage(image);
        }

        return false;
    };

    const onRemoveImageHandler = (): boolean => {
        setSingleImage([]);

        return true;
    };

    const processImage = (record: NewsPostCategorySingle) => {
        for (let i = 0; i < singleImage.length; i++) {
            const image = singleImage[i];

            if (image.status === 'done') {
                continue;
            }

            const data = new FormData();
            data.append('category', `${record.pk}`);
            data.append('title', `${image.name}`);
            data.append('file', image as RcFile);
            doRequest('/news/category/image/', RequestType.Post, data);
        }

        navigate(`${APP_PREFIX_PATH}/news/categories`);
    };

    const { id, currentData, isLoading, form, onFinishHandler } = useCreateAndUpdate<NewsPostCategorySingle>('/news/categories/', processImage);

    useEffect(() => {
        if (!currentData) return;

        const image = currentData.image;

        if (image == null)
            return;

        setSingleImage([{
            uid: `${image.pk}`,
            name: image.title,
            status: 'done',
            url: image.bytes_url,
            thumbUrl: image.bytes_url

        }]);
    }, [currentData]);

    // Wait until all necessary data is loaded
    if (id && !currentData) {
        return (
            <>
                <PageHeader title={id ? <FormattedMessage id="news.category.edit"  /> : <FormattedMessage id="news.category.create" /> } />
                <Loader />
            </>
        );
    }

    return (
        <>
            <PageHeader title={id ? <FormattedMessage id="news.category.edit"  /> : <FormattedMessage id="news.category.create" /> } />

            <Form layout="vertical" form={form} onFinish={onFinishHandler} initialValues={{
                name: currentData?.name ?? ''
            }}>
                <Card>
                    <Row gutter={[15, 0]}>
                        <Col span={24}>
                            <Form.Item name="name" label={<FormattedMessage id="name" />} rules={[{ required: true }]}>
                                <Input />
                            </Form.Item>
                        </Col>
                        <Col span={24} lg={6}>
                            <Form.Item name="upload"  rules={[{ required: false }]}>
                                <Upload listType="picture"  beforeUpload={beforeImageUploadHandler}
                                    onRemove={onRemoveImageHandler} fileList={singleImage} showUploadList={{ showPreviewIcon: false }}>
                                    {singleImage.length < 1 && (<div >
                                        <Button type="default"><FormattedMessage id="upload-image"  /></Button>
                                    </div>)}
                                </Upload>
                            </Form.Item>
                        </Col>
                        <Col span={24}></Col>
                        <Col span={24}>
                            <Form.Item>
                                <Button type="primary" htmlType="submit" loading={isLoading}>
                                    <FormattedMessage id="submit" />
                                </Button>
                            </Form.Item>
                        </Col>
                    </Row>
                </Card>
            </Form>
        </>
    );
};

export default CreateOrUpdateNewsCategories;