import { Button, Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Overview from '../../../components/shared-components/Overview';
import moment from 'moment';
import { NewsPostSingle } from '../../../api-interfaces/News';
import { CheckOutlined } from '@ant-design/icons';

const columns = [
    {
        title: <FormattedMessage id="active" />,
        dataIndex: 'active',
        width: '80px',
        render: (_: any, record: NewsPostSingle) => {
            const now = moment();
            const start = moment(record.publish_date);
            const end = moment(record.publish_until);

            if (now.isBetween(start, end)) {
                return <CheckOutlined />;
            }

            return null;
        }
    },
    {
        title: <FormattedMessage id="title" />,
        dataIndex: 'title',
        key: 'title',
        render: (_: string, record: NewsPostSingle) => (
            <Tooltip title={<FormattedMessage id="edit" />}>
                <Link to={`${APP_PREFIX_PATH}/news/${record.pk}`}>{record.title}</Link>
            </Tooltip>
        )
    },
    {
        title: <FormattedMessage id="category" />,
        dataIndex: ['category', 'name'],
        key: 'category',
        render: (name: string, record: NewsPostSingle) => (
            <Tooltip title={<FormattedMessage id="edit" />}>
                <Link to={`${APP_PREFIX_PATH}/news/categories/${record.category.pk}`}>{name}</Link>
            </Tooltip>
        )
    },
    {
        title: <FormattedMessage id="published-at" />,
        dataIndex: 'publish_date',
        key: 'published_at',
        responsive: ['lg' as const],
        render: (date: string) => <FormattedDate value={moment(date).toDate()} day="2-digit" month="long" year="numeric" hour="2-digit" minute="2-digit" />
    },
    {
        title: <FormattedMessage id="published-until" />,
        dataIndex: 'publish_until',
        key: 'published_until',
        responsive: ['lg' as const],
        render: (date: string) => <FormattedDate value={moment(date).toDate()} day="2-digit" month="long" year="numeric" hour="2-digit" minute="2-digit" />
    },
    {
        title: <FormattedMessage id="unique_reads" />,
        dataIndex: 'unique_reads',
        key: 'unique_reads',

    },
    {
        title: <FormattedMessage id="show_on_screen" />,
        dataIndex: 'show_on_bizplay_screen',
        key: 'show_on_bizplay_screen',
        render: (show_on_screen: boolean) => show_on_screen ? <FormattedMessage id="yes" /> : <FormattedMessage id="no" />
    },
];

const News = () => {
    const { pathname } = useLocation();

    const extraTopItems = (
        <>
            { pathname?.includes('overview') && <Link to={`${APP_PREFIX_PATH}/news/archived`}><Button><FormattedMessage id="view-archived" /></Button></Link>}
            { pathname?.includes('archived') && <Link to={`${APP_PREFIX_PATH}/news/overview`}><Button><FormattedMessage id="view-active" /></Button></Link>}
        </>
    );

    return (
        <Overview
            title={<FormattedMessage id="news.overview" />}
            endpoint="/news/"
            endpointOptions={{ archived: pathname?.includes('archived') ? 'True' : 'False' }}
            columns={columns}
            searchKeys={['title']}
            extraTopItems={extraTopItems}
            canCreate
            canArchive={pathname?.includes('overview')}
            canRestore={pathname?.includes('archived')} />
    );
};

export default News;