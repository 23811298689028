import { Button, message, Tag, Tooltip } from 'antd';
import { SendOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import { FormattedMessage, useIntl } from 'react-intl';
import Overview from '../../../components/shared-components/Overview';
import { UserSingle } from '../../../api-interfaces/Users';
import useApiRequest, { RequestType } from '../../../hooks/useApiRequest';

const columns = [
    {
        title: <FormattedMessage id="name" />,
        dataIndex: 'first_name',
        key: 'name',
        render: (_: string, record: UserSingle) => (
            <Tooltip title="Edit">
                <Link to={`${APP_PREFIX_PATH}/users/${record.pk}`}>{record.first_name} {record.last_name}</Link>
            </Tooltip>
        )
    },
    {
        title: <FormattedMessage id="email" />,
        dataIndex: 'email',
        key: 'email'
    },
    {
        title: <FormattedMessage id="tags" />,
        dataIndex: 'is_staff',
        key: 'is_staff',
        responsive: ['lg' as const],
        render: (isStaff: boolean) => {
            if (isStaff) {
                return (
                    <Tag color='volcano' key="admin">
                        ADMIN
                    </Tag>
                );
            }

            return null;
        },
    },
    {
        title: <FormattedMessage id="department" />,
        dataIndex: ['department', 'name'],
        key: 'department',
        responsive: ['md' as const],
        render: (name: string, record: UserSingle) => (
            <Tooltip title={<FormattedMessage id="edit" />}>
                <Link to={`${APP_PREFIX_PATH}/departments/${record.department?.pk}`}>{name}</Link>
            </Tooltip>
        )
    }
];

const extraTopItems = (
    <>
        <Link to={`${APP_PREFIX_PATH}/users/create`}><Button type="primary"><FormattedMessage id="create" /></Button></Link>
        <Link to={`${APP_PREFIX_PATH}/users/bulk/add`}><Button type="primary"><FormattedMessage id="users.bulk-add" /></Button></Link>
    </>
);

const Users = () => {
    const { doRequest } = useApiRequest();
    const intl = useIntl();

    const resendAppMailHandler = (email: string) => {
        doRequest(`/users/app-download-request/${email}/`, RequestType.Post).then(() => {
            message.success(intl.formatMessage({ id: 'users.sent-mail' }, { email: email }));
        }).catch(() => {
            message.error(intl.formatMessage({ id: 'users.sent-mail-failed' }));
        });
    };

    const extraActions = [
        {
            key: 'app-mail',
            label: <FormattedMessage id="users.resend-app-mail" />,
            icon: <SendOutlined />,
            onClick: (record: UserSingle) => resendAppMailHandler(record.email)
        }
    ];

    return (
        <Overview
            title={<FormattedMessage id="users.overview" />}
            endpoint="/users/"
            columns={columns}
            searchKeys={['first_name', 'last_name', 'email']}
            extraTopItems={extraTopItems}
            extraActionItems={extraActions}
            canDelete />
    );
};

export default Users;