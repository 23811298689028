import { FormattedDate, FormattedMessage } from 'react-intl';
import useApiRequest, { RequestType } from '../../../../hooks/useApiRequest';
import useAppDispatch from '../../../../hooks/useAppDispatch';
import useAppSelector from '../../../../hooks/useAppSelector';
import { authActions } from '../../../../store/slices/authSlice';
import classes from './ProvileNav.module.less';

const ProfileNav = () => {
    const { doRequest } = useApiRequest();
    const user = useAppSelector(state => state.auth.user);
    const dispatch = useAppDispatch();

    const onLogoutHandler = () => {
        doRequest('/auth/logout', RequestType.Get).then(() => {
            dispatch({
                type: authActions.logout
            });
        });
    };

    return (
        <div className={classes.ProfileInfo}>
            <span className={classes.ProfileName}>{user?.first_name ? `${user.first_name} ${user?.last_name}` : user?.email} | <span className={classes.ProfileLogout} onClick={onLogoutHandler}><FormattedMessage id="header.logout" /></span></span>
            <span className={classes.ProfileLoggedIn}><FormattedMessage id="header.previous" /> <FormattedDate value={new Date()} year="numeric" month="long" day="2-digit" hour="2-digit" minute="2-digit" /></span>
        </div>
    );
};

export default ProfileNav;