import antdNlNL from 'antd/es/locale/nl_NL';
import nlMsg from '../locales/nl_NL.json';

const NlLang = {
    antd: antdNlNL,
    locale: 'nl-NL',
    messages: {
        ...nlMsg
    }
};

export default NlLang;