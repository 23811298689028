import { Button, Tooltip } from 'antd';
import { Link, useLocation } from 'react-router-dom';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import { FormattedDate, FormattedMessage } from 'react-intl';
import Overview from '../../../components/shared-components/Overview';
import { IdeaSingle } from '../../../api-interfaces/Ideas';
import moment from 'moment';

const columns = [
    {
        title: <FormattedMessage id="title" />,
        dataIndex: 'title',
        key: 'title',
        render: (_: string, record: IdeaSingle) => (
            <Tooltip title={<FormattedMessage id="view" />}>
                <Link to={`${APP_PREFIX_PATH}/ideas/${record.pk}`}>{record.title}</Link>
            </Tooltip>
        )
    },
    {
        title: <FormattedMessage id="created-at" />,
        dataIndex: 'created_at',
        key: 'created_at',
        responsive: ['lg' as const],
        render: (date: string) => <FormattedDate value={moment(date).toDate()} day="2-digit" month="long" year="numeric" hour="2-digit" minute="2-digit" />
    },
    {
        title: <FormattedMessage id="votes" />,
        dataIndex: 'total_votes',
        key: 'cotes',
        responsive: ['md' as const],
        width: '80px',
    },
    {
        title: <FormattedMessage id="comments" />,
        dataIndex: 'comments',
        key: 'comments',
        responsive: ['md' as const],
        width: '100px',
        render: (item: any) => item.length
    },
    {
        title: <FormattedMessage id="category" />,
        dataIndex: ['category', 'name'],
        key: 'category',
        responsive: ['sm' as const],
        render: (name: string, record: IdeaSingle) => (
            <Tooltip title={<FormattedMessage id="edit" />}>
                <Link to={`${APP_PREFIX_PATH}/ideas/categories/${record.category.pk}`}>{name}</Link>
            </Tooltip>
        )
    }
];

const Ideas = () => {
    const { pathname } = useLocation();

    const extraTopItems = (
        <>
            { pathname?.includes('overview') && <Link to={`${APP_PREFIX_PATH}/ideas/archived`}><Button><FormattedMessage id="view-archived" /></Button></Link>}
            { pathname?.includes('archived') && <Link to={`${APP_PREFIX_PATH}/ideas/overview`}><Button><FormattedMessage id="view-active" /></Button></Link>}
        </>
    );

    return (
        <Overview
            title={<FormattedMessage id="ideas.overview" />}
            endpoint="/ideas/"
            endpointOptions={{ archived: pathname?.includes('archived') ? 'True' : 'False' }}
            columns={columns}
            searchKeys={['title']}
            extraTopItems={extraTopItems}
            canArchive={pathname?.includes('overview')}
            canRestore={pathname?.includes('archived')}/>
    );
};

export default Ideas;