import { CommentOutlined, LikeFilled } from '@ant-design/icons';
import { Card, Col, Comment, Row, Tooltip } from 'antd';
import moment from 'moment';
import 'moment/locale/nl';
import { useEffect, useState } from 'react';
import Chart from 'react-apexcharts';
import { FormattedMessage, useIntl } from 'react-intl';
import { Statistics } from '../../../../api-interfaces/Statistics';
import useApiRequest, { RequestType } from '../../../../hooks/useApiRequest';

import 'moment/locale/nl';
import { IdeaSingle } from '../../../../api-interfaces/Ideas';

const chartOptionsDailyVotes = {
    toolbar: {
        show: false
    },
    chart: {
        id: 'daily-votes',
        zoom: {
            enabled: false
        }
    },
    xaxis: {
        categories: ['']
    }
};

const chartOptionsDailyComments = {
    toolbar: {
        show: false
    },
    chart: {
        id: 'daily-votes',
        zoom: {
            enabled: false
        }
    },
    xaxis: {
        categories: ['']
    }
};

const IdeasStatistics = () => {
    const { doRequest } = useApiRequest();
    const [votesData, setVotesData] = useState<Statistics|null>(null);
    const [commentsData, setCommentsData] = useState<Statistics|null>(null);
    const [top5Data, setTop5Data] = useState<IdeaSingle[]|null>(null);
    const intl = useIntl();
    moment.locale(intl.locale);

    useEffect(() => {
        doRequest('stats/ideas-votes-plot-data/', RequestType.Get).then(response => {
            setVotesData(response.data);
        });

        doRequest('stats/ideas-comments-plot-data/', RequestType.Get).then(response => {
            setCommentsData(response.data);
        });

        doRequest('/ideas/?ordering=-nr_votes,-nr_comments&archived__value=False&limit=5', RequestType.Get).then(response => {
            setTop5Data(response.data.results);
            //  console.log(response.data.results);
        });
    }, [doRequest]);

    const actions = (data: IdeaSingle) => [
        <Tooltip key={'v_' + data.pk} title={<FormattedMessage id="like" />}>
            <span>
                <LikeFilled />
                <span className="comment-action">{data.total_votes}</span>
            </span>
        </Tooltip>,
        <Tooltip key={'c_' + data.pk} title={<FormattedMessage id="comments" />}>
            <span>
                <CommentOutlined />
                <span className="comment-action">{data.comments.length}</span>
            </span>
        </Tooltip>
    ];

    const voteOptions = chartOptionsDailyVotes;
    voteOptions.xaxis.categories = votesData?.dates ?? [];

    const commentOptions = chartOptionsDailyComments;
    commentOptions.xaxis.categories = commentsData?.dates ?? [];

    return (
        <Card title={<FormattedMessage id="overview.stats.ideas" />} loading={!commentsData || !votesData}>
            <Row>
                <Col span={24} md={12}>
                    <h2><FormattedMessage id="overview.stats.ideas.top" /></h2>
                    { top5Data?.map(data =>
                        <Comment key={data.pk} author={data.author ? `${data.author?.first_name} ${data.author?.last_name}` : ''} content={data.title}
                            actions={actions(data)} datetime={
                                <Tooltip title={moment(data.created_at).format('MMMM d, YYYY')}>
                                    <span>{moment.duration(moment(data.created_at).diff(moment.now())).humanize(true)}</span>
                                </Tooltip>
                            } />
                    )}

                </Col>
                <Col span={24} md={12}>
                    <h2><FormattedMessage id="overview.stats.ideas.daily-votes" /></h2>
                    <Chart
                        height="200px"
                        options={voteOptions}
                        series={votesData?.series ?? []}
                        type="line"
                    />

                    <h2><FormattedMessage id="overview.stats.ideas.daily-comments" /></h2>
                    <Chart
                        height="200px"
                        options={commentOptions}
                        series={commentsData?.series ?? []}
                        type="line"
                    />
                </Col>
            </Row>
        </Card>
    );
};

export default IdeasStatistics;