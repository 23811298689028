import { Spin } from 'antd';
import classes from './Spinner.module.less';

const Spinner = () => {
    return (
        <div className={classes.centerHorizontally}>
            <Spin />
        </div>);
};

export { Spinner };
