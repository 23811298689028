import { useNavigate, useParams } from 'react-router-dom';
import useApiRequest, { RequestType } from './useApiRequest';
import { useEffect, useState } from 'react';
import useForm from 'antd/lib/form/hooks/useForm';
import { APP_PREFIX_PATH } from '../constants/AppConstants';

const useCreateAndUpdate = <T, >(endpoint: string, onFinished: (record: T) => void) => {
    const [form] = useForm();
    const navigate = useNavigate();
    const { id } = useParams();
    const [currentData, setCurrentData] = useState<T | null>(null);
    const { isLoading, doRequest } = useApiRequest();

    // Retrieve data if editing
    useEffect(() => {
        if (!id) {
            setCurrentData(null);
            return;
        }

        doRequest(`${endpoint}${id}/`, RequestType.Get).then(response => {
            setCurrentData(response.data);
        }).catch(error => {
            navigate(`${APP_PREFIX_PATH}/overview`);
        });
    }, [id]);

    // Clear form when we switch to create from edit
    useEffect(() => {
        return () => {
            if (!id) {
                form.resetFields();
            }
        };
    }, [currentData, id, form]);

    // Finish handler
    const onFinishHandler = (values: object) => {
        if (isLoading) return;

        if (id) {
            doRequest(`${endpoint}${id}/`, RequestType.Put, values).then((response) => {
                onFinished(response.data);
            });

            return;
        }

        doRequest(`${endpoint}`, RequestType.Post, values).then((response) => {
            onFinished(response.data);
        });
    };

    return {
        id,
        currentData,
        isLoading,
        form,
        onFinishHandler
    };
};

export default useCreateAndUpdate;