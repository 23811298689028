import NavigationConfig from '../../../config/NavigationConfig';
import { useLocation, useNavigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { APP_PREFIX_PATH } from '../../../constants/AppConstants';
import { MenuProps, Menu as MenuAntd } from 'antd';
import { MenuInfo } from 'rc-menu/lib/interface';

interface Props {
    toggleMobileNav?: () => void
}

const Menu = (props: Props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState<string[]>([]);
    const selectedKey = location.pathname.substring(APP_PREFIX_PATH.length + 1);

    useEffect(() => {
        const submenuKey = selectedKey.substring(0, selectedKey.indexOf('/'));
        setOpenKeys([submenuKey]);
    }, [selectedKey]);

    const onClickHandler = (data: MenuInfo) => {
        if (props.toggleMobileNav) {
            props.toggleMobileNav();
        }

        navigate(data.key);
    };

    const onOpenChangeHandler: MenuProps['onOpenChange'] = (keys) => {
        const latestOpenKey = keys.find(key => openKeys.indexOf(key) === -1);

        setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    };

    return (
        <>
            <MenuAntd style={{ overflowX: 'hidden' }} items={NavigationConfig} mode="inline" onOpenChange={onOpenChangeHandler} openKeys={openKeys} selectedKeys={[selectedKey]} onClick={onClickHandler} />
        </>
    );
};

export default Menu;