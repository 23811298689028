import { Col, Layout, Row } from 'antd';
import React from 'react';
import AuthViews from '../../views/auth-views';
import classes from './AuthLayout.module.less';

const AuthLayout = () => {
    return (
        <Layout style={{ minHeight: '100vh' }} className={classes.Main}>
            <Layout.Content style={{ padding: '15px' }}>
                <Row align="middle" justify="center" style={{ height: '100vh' }}>
                    <Col className={classes.Body} span={24} md={12} xxl={6}>
                        <div className={classes.Logo}>
                            <img src="/app-logo-white.png" alt="logo" />
                        </div>
                        <AuthViews />
                    </Col>
                </Row>
            </Layout.Content>
        </Layout>
    );
};

export default AuthLayout;