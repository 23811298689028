import { FormattedMessage } from 'react-intl';
import { Popconfirm } from 'antd';
import * as React from 'react';
import { ReactNode } from 'react';

interface Props {
    onConfirm: (e?: React.MouseEvent<HTMLElement>) => void,
    children: ReactNode
}

const ActionPopconfirm = (props: Props) => (
    <Popconfirm
        title={<FormattedMessage id="are-you-sure" />}
        onConfirm={props.onConfirm}
        placement="left"
        cancelText={<FormattedMessage id="no" />}
        okText={<FormattedMessage id="yes" />}>
        {props.children}
    </Popconfirm>
);

export default ActionPopconfirm;